import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './about.css';
import { useTranslation } from 'react-i18next';

function About(){
    const { t } = useTranslation();

    return (
        <Container breakpoint='xxl' className='mt-5 a-i-center section' id='about'>
            <Row>
                <Col>
                    <div className='timeline p-5'>
                        <div className='timeitem'>
                            <span className='timeyear right'>Today</span>
                            <p>{t('about.today')}</p>
                        </div>
                        <div className='timeitem'>
                            <span className='timeyear left'>2023</span>
                            <p>{t('about.2023')}</p>
                        </div>
                        <div className='timeitem'>
                            <span className='timeyear right'>2022</span>
                            <p>{t('about.2022')}</p>
                        </div>
                        <div className='timeitem'>
                            <span className='timeyear left'>2021</span>
                            <p>{t('about.2021')}</p>
                        </div>
                        <div className='timeitem'>
                            <span className='timeyear right'>2020</span>
                            <p>{t('about.2020')}</p>
                        </div>
                        <div className='timeitem'>
                            <span className='timeyear left'>2014</span>
                            <p>{t('about.2014')}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default About;