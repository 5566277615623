import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  eng: {
    translation: {
        "header":{
            "1":"About me",
            "2":"Web Sites",
            "3":"Hire me",
            "4":"Web Apps",
            "close":"Close"
        },
        "marcexl":{
            "h3":"Hello! ",
            "span":"My name is",
            "name": "Mr. Marcelo Gallardo",
            "buttons":{
                "download":"Download Resume",
                "hireme":"Hire me"
            },
            "link":"MarceloGallardoResume.pdf",
        },
        "about":{
            "today":"I am a frontend developer, I specialize in frontend layout, Work with API services to display de json results. I like being able to meet the proposed goals and objectives. Im opening to hear offers",
            "2023":"I ve joined to the team of CybeleSoft, enterprise dedicated to build aplicacion and software development. Im working with the new version of Thinfinity using VUE and Vanilla Javascript",
            "2022":"I worked for the company 121Ecommerce, there I learned to develop Front using Magento2 with ubuntu. I used the nesting concept with LESS and compiled the styles with Grunt. I also worked with PHTML and XML files. At 121Ecommerce we work with agile methodologies and I had to speak English every day with clients from the United States.",
            "2021":"I worked at Crowdvac on the Vidgo web application project and the layout for the TV software. The request was that we use HTML5, CSS, using MDBootstrap. Vanilla JS consumption of rest apis and mosaic layout pattern.",
            "2020":"I was able to work for a few months on the Correo Argentino Autosobre project. Maintenance and development of the SIE module, Online using Bootstrap, PHP SOAP, JQUERY and Oracle.",
            "2014":"For 8 years I worked in the development and maintenance of internal applications at the Universidad del Salvador. There I applied responsive design to all USAL applications using PHP, HTML, CSS and JQUERY. I participated in several projects where a migration from PEARL to PHP 5 and PHP 7."
        },
        "services":{
            "card1":{
                "title":"WEB DEVELOPMENT",
                "text":"REACT, WordPress, Woocommerce, Magento2, Landing pages HTML + JQUERY. Prime UX, Bootstrap. Node, Firebase, Mysql or JSON files for db."
            },
            "card2":{
                "title":"APPS",
                "text":"High experience in Cordova apps development, now I im working with React and Firebase."
            },
            "card3":{
                "title":"ENJOY",
                "text":"I strive to provide a high quality product and to achieve 100% satisfaction in the final product."
            },
            "buttons":"Try and Test"
        },
        "contact":{
            "title":"Please complete the form below",
            "title2":"CONTACT",
            "name":"Name",
            "fname":"Surname",
            "phone":"Phone",
            "consult":"Tell me what you need",
            "button":"Send",
            "wame":"Hello%20I%20saw%20your%20site%20its%20great",
            "msj":"Thanks for contacting me! I will try to answer as soon as possible.",
            "error":"Oops, there is a unicorn here. Try again later please."
        },
        "common":{
            "button":" watch"
        }
    }
  },
  esp: {
    translation: {
        "header":{
            "1":"Sobre mí",
            "2":"Sitios Web",
            "3":"Contratame",
            "4":"Web Apps",
            "close":"Cerrar"
        },
        "marcexl":{
            "h3":"¡Hola! ",
            "span":"Mi nombre es",
            "name": "Sr. Marcelo Gallardo",
            "buttons":{
                "download":"Descargar CV",
                "hireme":"Contratame"
            },
            "link":"MarceloGallardoCV.pdf"
        },
        "about":{
            "today":"Me especializo en diseño frontend, css flex, sass o less. Me gusta poder cumplir con las metas y objetivos propuestos. Hablo inglés fluido y estoy con ganas de sumarme a nuevos proyectos.",
            "2023":"Me sumé al equipo de desarrollo de software en CybeleSoft, empresa que se dedica a la crear aplicacion para poder reutilizar el software propio de las empresas, nueva version con VUE y Vanilla Javascript.",
            "2022":"Trabajé para la empresa 121Ecommerce, alli aprendí a desarrollar FE usando Magento2 con Ubuntu. Utilicé el concepto de nesting con LESS y compilé los estilos con Grunt. También trabajé con archivos PHTML y XML. En 121Ecommerce trabajamos con metodologías agiles y tuve la oportunidad de dialogar en inglés todos los días con clientes de Estados Unidos.",
            "2021":"Trabajé en Crowdvac en el proyecto de la aplicación Vidgo para web y el maquetado para el software de TV. El pedido era que utilicemos HTML5, CSS, usando MDBootstrap. Vanilla JS consumo de api rest y patrón de diseño mosaico.",
            "2020":"Pude trabajar unos meses en el proyecto de Autosobre de Correo Argentino. Mantenimiento y desarollo del modulo SIE Online usando Bootstrap, SOAP de PHP, JQUERY y Oracle.",
            "2014":"Durante 8 años trabajé en desarrollo y mantenimiento de aplicaciones internas en la Universidad del Salvador. Allí apliqué diseño responsive a todas las aplicaciones de la USAL usando PHP, HTML, CSS y JQUERY. Participé de varios proyectos donde se realizó una migración de PEARL a PHP 5 y PHP 7."
        },
        "services":{
            "card1":{
                "title":"DESARROLLO WEB",
                "text":"REACT, WordPress, Woocommerce, Magento2, Landing pages HTML + JQUERY. Prime UX, Bootstrap. Node, Firebase, Mysql o archivos JSON como db."
            },
            "card2":{
                "title":"APPS",
                "text":"Años de experiencia en el desarrollo de aplicaciones con Cordova, ahora estoy realizando proyectos en React y Firebase."
            },
            "card3":{
                "title":"SATISFACCIÓN",
                "text":"Me esfuerzo por ofrecer un producto de alta calidad y lograr el 100% de satisfacción en el producto final."
            },
            "buttons":"Probá una app"
        },
        "contact":{
            "title":"Enviame tus datos para contactarte",
            "title2":"CONTACTAME",
            "name":"Nombre",
            "fname":"Apellido",
            "phone":"Celular",
            "consult":"Contame que necesitas",
            "button":"Enviar",
            "wame":"Hola%20me%20contacto%20porque%20vi%20la%20web%20y%20me%20encanto!",
            "msj":"¡Muchas gracias por contactarme! Trataré de responder a la brevedad.",
            "error":"Ups. Algo puede malir sal. Te pido disculpas."
        },
        "common":{
            "button":" yendo"
        }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "esp", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;