import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./utils.css";
import { useTranslation } from "react-i18next";
//const text = {eng: "Live view", arg: "Ir al sitio"};

export default function Utils() {
  const { t, i18n } = useTranslation();

  const lang = i18n.resolvedLanguage;

  const data = [
    {
      id: 1,
      url: "https://marcexl.com.ar/app/vidgo/",
      class: "",
      title: "Vidgo App",
      video: "https://marcexl.com.ar/videos/vidgo.mp4",
      desc: {
        eng: "Vidgo is an application to watch movies, sports and series online in the United States. These screens were designed for the TV software with MDB Bootstrap. Use of Mosaic design pattern. It was quite a challenge to layout the Guide TV screen, especially because of the time and day calculations. cell sizes based on the length of the movie. Other screens were also designed as the Search Page, Settings, VOD page and Recordings. This site was made with HTML5, JQUERY AND Vanilla JS.",
        esp: "Vidgo es una aplicación para ver películas, deportes y series online en los Estados Unidos. Estas pantallas fueron maquetadas para el software de TV con MDB Boostrap. Patron de diseño Mosaico. Fue bastante desafío maquetar la pantalla de Guide TV, sobre todo por los calculos de la hora y día. tamaños de las celdas en base a la duración de la película. Otras pantallas también fueron maquetadas como la Search Page, Settings, VOD page y Recordings. Este sitio fue hecho con HTML5, JQUERY Y Vanilla JS.",
      },
    },
    {
      id: 2,
      url: "https://marcexl.com.ar/app/quiz/",
      class: "reverse",
      title: "Quiz App",
      video: "https://marcexl.com.ar/videos/quiz.mp4",
      desc: {
        eng: "Quiz App is a webapp to have as an example how easy quizzes can be created with a JSON file and Prime REACT. By customizing the JSON you can add more questions, different answers and depending on the type of question you can choose to select several answers, a single answer or complete the sentence.",
        esp: "Quiz App es una webapp para tener como ejemplo como se pueden crear quiz fáciles con un archivo JSON y Prime REACT. Editando el JSON se pueden agregar mas preguntas, distintas respuestas y depende el tipo de pregunta se puede optar por seleccionar varias respuestas, una sola o completar frase.",
      },
    },
    {
      id: 3,
      url: "https://marcexl.com.ar/app/tickets/",
      class: "",
      title: "QR Ticket Generator App",
      video: "https://marcexl.com.ar/videos/tickets.mp4",
      desc: {
        eng: "This is a system to generate tickets with QR code. The code contains the ID number and the event code. This web app was made with REACT and Bootstrap React. If you need to save more data, you can customize it to your liking. The system comes with a fairly simple one entry design but can be programmed for other types of invitations. The entry should be able to arrive by email and can also be downloaded in pdf with PHP and FPDF.",
        esp: "Este es un sistema para generar tickets con código QR. El código contiene el número de DNI y el código de evento. Esta web app fue hecha con REACT y Boostrap React. De necesitar guardar mas datos se puede customizar a gusto. El sistema viene con un diseño de una entrada bastante simple, pero se puede programar para otro tipos de invitaciones. La entrada deberia poder llegar por email y se puede descargar también en pdf a través de un servicio PHP y FPDF.",
      },
    },
    {
      id: 4,
      url: "https://marcexl.com.ar/app/ictinos/",
      class: "reverse",
      title: "Ictinos App",
      video: "https://marcexl.com.ar/videos/ictinos.mp4",
      desc: {
        eng: "This webapp is not public since it was made for some screens that are located in Chile in a real estate gallery. It is designed to be viewed in Full HD Touch Screens and all the data from the different houses comes from a JSON file. It was built with MDB Bootstrap, JQUERY and HTML5. The detail of the boxes floating in the background is what also gave life to this portfolio.",
        esp: "Esta webapp no esta pública ya que fue hecha para unas pantallas que estan ubicadas en Chile en una galería inmobiliaria. Esta pensada para proyectarse en pantallas touch en Full HD y todos los datos de las distintas casas vienen de un archivo JSON. Fue maquetado con MDB Boostrap, JQUERY y HTML5. El detalle de los box flotando de fondo es lo que le dió vida también a este porfolio.",
      },
    },
    {
      id: 5,
      url: "https://marcexl.com.ar/app/mural/",
      class: "",
      title: "Mural App",
      video: "https://marcexl.com.ar/videos/mural.mp4",
      desc: {
        eng: "This is a message board, the interesting thing about this is that it connects directly to the GOOGLESHEETS API through an APPSCRIPT service. In this way you send the data directly to Google Sheets and also raise new messages from there. The cards appear fadein, they have different colors and varied sizes to give the front more dynamism. Simple but it works.",
        esp: "Esto es un mural para dejar mensajes, lo interesante de esto es que se conecta directamente a la API de GOOGLESHEETS a través de un servicio en APPSCRIPT. De este modo envias la data directo al Google Sheets y tambien levantas los nuevos mensajes desde ahi. Las cards aparecen con fadein tienen distintos colores y tamaños variados para darle mas dinamismo al front. Sencillo pero funciona.",
      },
    },
  ];

  const [content, setContent] = useState(null);

  useEffect(() => {
    setContent(data);
  }, []);

  return (
    <Container breakpoint="xxl" className="mt-5 a-i-center section" id="utils">
      <div className="carousel-container">
        {content &&
          content.map((k) => {
            return (
              <div key={k.id} className={`video-card my-5 ${k.class}`}>
                <video loop autoPlay muted className="video">
                  <source src={k.video} type="video/mp4"></source>
                </video>
                <div className="video-card-body">
                  <h2>{k.title}</h2>
                  <p>{lang === "esp" ? k.desc.esp : k.desc.eng}</p>
                  <a href={k.url} target="_blank" rel="noreferrer">
                    <Button type="text" className="mt-4" variant="primary">
                      {t("common.button")}
                    </Button>
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    </Container>
  );
}
