
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typewriter from 'typewriter-effect';
import MovingText from 'react-moving-text';
import Button from 'react-bootstrap/Button';
import './marcexl.css';
import { useTranslation } from 'react-i18next';

function Marcexl(){

    const { t } = useTranslation();

    return (    
    <Container id='marcexl' className='full-h section'>
        <Row>
            <Col className='salute'>
                <h3>{t('marcexl.h3')}<span>{t('marcexl.span')}</span></h3>
                <h1>
                    <Typewriter
                        options={{
                            strings: [t('marcexl.name')] ,
                            autoStart: true,
                            loop: true,
                            pauseFor:8000
                        }}
                    />
                </h1>
                <h2>
                    <MovingText
                        type="jelly"
                        duration="1000ms"
                        delay="5s"
                        direction="alternate"
                        timing="ease"
                        iteration="1"
                        fillMode="none">
                        Frontend Developer
                    </MovingText>
                </h2>
                <div className='marcexl-buttons'>
                    <a href= { `./Download/${t('marcexl.link')}` }  target="_blank" rel="noreferrer" >
                        <Button variant="primary"> 
                            {t('marcexl.buttons.download')}         
                        </Button>
                    </a>
                    <a href="tel:+5491123922906">
                        <Button variant="secondary">
                            {t('marcexl.buttons.hireme')}         
                        </Button>
                    </a>
                </div>
            </Col>
        </Row>
    </Container>
    );
}

export default Marcexl;
