import { useEffect, useState, useContext} from "react";
import Squares from "./Squares";
import "./squares.css";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export default function SquaresContainer() {
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];    
 
  const showCountValue = (value) => {
    setCount(value)
    setShow(true)
  }

  const hideToast = () => {
    setShow(false)
  };

  return (
        <>
            <ToastContainer className="p-3 position-fixed" position="top-end" style={{ zIndex: 11 }} delay={3000} autohide>
                <Toast show={show} onClose={hideToast} bg="Success">
                    <Toast.Header>
                        <strong className="me-auto">GREAT!</strong>
                    </Toast.Header>
                    <Toast.Body>{ count >= 10 ? `Well Done!` : `${count} box destroy!`}</Toast.Body>
                </Toast>
            </ToastContainer>
           
            <div className="squares-container" id="squarescontainer">
                {array.map((t) => {
                    return <Squares key={t} id={t} onCountChange={showCountValue}/>;
                })}
                <div id="follower"></div>
            </div>
        </>
  );
}
