import React, { useState, Component } from "react";
import Marcexl from './Components/Marcexl/Marcexl'
import Header from './Components/Header/Header'
import ChangeLang from './Components/ChangeLang';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Projects from './Components/Projects/Projects';
import Utils from "./Components/Utils/Utils";
import Contact from './Components/Contact/Contact';
import ScrollSpy from "react-ui-scrollspy";
import SquaresContainer from "./Components/Squares/SquaresContainer";
import './app.css';

function App(){
  return (
    <>
    <Header />
    <ChangeLang />
    <ScrollSpy>
      <Marcexl />
      <About id="about"/>
      <Services id="services" />
      <Projects id="projects" />
      <Utils id="utils" />
      <Contact id="contact" />
      <SquaresContainer />
    </ScrollSpy>
    </>
  );
  
}

export default App;