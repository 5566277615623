import React, {useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import './projects.css';
import Tilt from 'react-parallax-tilt';

//const text = {eng: "Live view", arg: "Ir al sitio"};

export default function Projects(){

    const data = [
        {id:1, url:"https://hawaiistudio.com/newsite/html/sample1.html", class: "br1", title: "Bootstrap", image: "019.jpg" },
        {id:2, url:"https://agencybroadcast.com/", class: "br2", title: "React", image: "016.jpg" },
        {id:3, url:"https://ccjmdi.org.ar/", class: "", title: "Wordpress", image: "014.jpg" },
        {id:4, url:"http://insuquim.com.py/", class: "", title: "Wordpress", image: "017.jpg" },
        {id:5, url:"https://diaf.com.ar/", class: "", title: "Bootstrap + AOS animations", image: "011.jpg" },
        {id:6, url:"https://mingohardseltzer.com.ar/", class: "", title: "Wordpress", image: "008.jpg" },
        {id:7, url:"https://staging.rtithreads360.com/", class: "", title: "Magento2", image: "003.jpg" },
        {id:8, url:"http://serviciosjuridicos.ar/", class: "", title: "React + Bootstrap", image: "006.jpg" },
        {id:9, url:"https://pasarelavestidos.com/", class: "br3", title: "Woocommerce", image: "002.jpg" },
        {id:10, url:"https://tucontabilidadya.com/", class: "br4", title: "Wordpress with SASS", image: "009.jpg" },    
    ];

    const [content, setContent] = useState(null);

    useEffect( () => {
        setContent(data);
    },[]);

    return (
        <Container breakpoint='xxl' className='mt-5 a-i-center section' id='projects'>
            <div className='grid-container'>
                {
                    content && content.map( t => {
                        return (
                                <div className="grid-item" key={t.id}>
                                    <Tilt>
                                        <a href={t.url} target='_blank' rel="noreferrer">
                                            <img src={require(`./${t.image}`)} alt='' className={`project-thumb ${t.class}`} />
                                            <span>{t.title}</span>
                                        </a>
                                    </Tilt>
                                </div>

                            )

                    })
                }
            </div>
        </Container>
    );
}
