import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import LanguageIcon from '@mui/icons-material/Language';
import AndroidIcon from '@mui/icons-material/Android';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import './services.css';
import { useTranslation } from 'react-i18next';

function Services() {
    const { t } = useTranslation();

    return (      
    <Container breakpoint='xxl' className='mt-5 a-i-center section' id='services'>
        <Row>
            <Col>
                <Card>
                    <LanguageIcon fontSize='large' />
                    <h2>{t('services.card1.title')}</h2>
                    <div className='card-text'>
                        {t('services.card1.text')}
                    </div>                
                </Card>
            </Col>
            <Col>
                <Card>
                    <AndroidIcon fontSize='large' />
                    <h2>{t('services.card2.title')}</h2>
                    <div className='card-text'>
                        {t('services.card2.text')}
                    </div>             
                    <a href='https://play.google.com/store/apps/details?id=ar.org.sgiar.app&hl=en&gl=US&pli=1'>
                        <Button variant="primary services-but">          
                            {t('services.buttons')}
                        </Button>
                    </a>
                </Card>
            </Col>
            <Col>
                <Card>
                    <ThumbUpIcon fontSize='large' />
                    <h2>{t('services.card3.title')}</h2>
                    <div className='card-text'>
                        {t('services.card3.text')}
                    </div>             
                </Card>
            </Col>
        </Row>
    </Container>
    );
}

export default Services;