import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './contact.css';

const errorMessage = "Please complete all the fields";
const successMessage = "Message sended";

function Contact() {
    const { t } = useTranslation();
    const [validated, setValidated] = useState(false);
    const [display, setDisplay] = useState('none');
    const [alertDisplay, setAlertDisplay] = useState('none');
    const [variant, setVariant] = useState('success');
    const [message, setMessage] = useState('');
    const [fname, setFname] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [consulting, setConsulting] = useState('');
    const [email, setEmail] = useState('');
    const [check, setCheck] = useState(false);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false)
        {
            setValidated(true);
            setCheck(true);
            event.preventDefault();
            event.stopPropagation();
            setMessage(errorMessage);
        }
        else
        {
            event.preventDefault();
            setCheck(true);
            setValidated(false);
            setDisplay('block');
            const data = JSON.stringify({ name: name, fname: fname, phone: phone, email: email, consulting: consulting });
            const url = 'https://marcexl.com.ar/Mail/mail.php';
            try {
                const response = await axios.post(url, data);
                if(response){
                    setTimeout(function(){
                        setDisplay('none');
                        setAlertDisplay('block');
                        setVariant('success');
                        setMessage(successMessage);
                        document.getElementById("contactform").reset()
                    },500);
                }
            } catch (error) {
                console.error('Error al hacer la solicitud:', error);
                setTimeout(function(){
                    setDisplay('none');
                    setVariant('danger');
                    setAlertDisplay('block');
                    setMessage('oops, error ocurred');
                },500);
            }
        }
    };

    return (
        <Container breakpoint='xxl' className='mt-5 a-i-center container section' id="contact">
            <Spinner animation="border" variant="secondary" style={{display: display}}/>
            <div className='row-container'>
                <Row className='p-5'>
                    <Col className='contact-container-form'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit} id="contactform">
                            <h5 className='mb-5'>{t('contact.title')}</h5>
                            <Form.Group as={Col} md="6" controlId="name" className='pr-2'>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder={t('contact.name')}
                                    defaultValue=""
                                    onChange={(e) => {setName(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="fname">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder={t('contact.fname')}
                                    defaultValue=""
                                    onChange={(e) => {setFname(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="email" className='pr-2'>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email"
                                    defaultValue=""
                                    onChange={(e) => {setEmail(e.target.value)}}

                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="phone">
                                <Form.Control
                                    required
                                    type="number"
                                    placeholder={t('contact.phone')}
                                    defaultValue=""
                                    onChange={(e) => {setPhone(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="consulting">
                                <Form.Control
                                    as="textarea"
                                    aria-label="With textarea"
                                    placeholder={t('contact.consult')}
                                    onChange={(e) => {setConsulting(e.target.value)}}
                                />
                            </Form.Group>
                            {check && <Alert variant={!validated ? 'success' : 'danger'} style={{display: 'block'}}> { message } </Alert>}
                            <Button type="submit">{t('contact.button')}</Button>
                        </Form>
                    </Col>
                </Row>
                <Row className='p-5'>
                    <Col>
                    <h6>{t('contact.title2')}</h6>
                        <address>
                            <a href="#home">
                                <HomeIcon />
                                <span>Colombres 758. (1218) Buenos Aires. Argentina.</span>
                            </a>
                            <br />
                            <a href={ `https://wa.me/5491123922906?text=${t('contact.wame')}`} >
                                <WhatsAppIcon />
                                <span>+5491123922906</span>
                            </a>
                            <br />
                            <a href="mailto:info@marcexl.com.ar">
                                <EmailIcon />
                                <span>info@marcexl.com.ar</span>
                            </a>
                        </address>
                    </Col>
                </Row>
            </div>
        </Container>
    );
  }

export default Contact;
