import React, {useState, useEffect} from "react";
import i18n from "i18next";
import Arg from './argentina.png';
import Usa from './eeuu.png';

export default function ChangeLang (){
    const [languaje, setLanguaje] = useState(false)
    const [flag, setflag] = useState(Arg)

    useEffect( () => {
        /* if is set */
        if(localStorage.getItem("lang"))
        {
            let l = localStorage.getItem("lang")
            setflag( l === "esp" ? Arg : Usa)
            setLanguaje(l === "esp" ? true : false)
            setTimeout(function(){
                i18n.changeLanguage(l);
            },50)
        }
    }, [])

    const switchLanguaje = () => {
        if(languaje)
        {
            setLanguaje(false)
            setflag(Usa)
            localStorage.setItem("lang", "eng");
            i18n.changeLanguage("eng");
        }else{
            setLanguaje(true)
            setflag(Arg)
            localStorage.setItem("lang", "esp");
            i18n.changeLanguage("esp");
        }
    }

    return (
        <div>
            <img src={flag} alt="Avatar" className="lenguaje" onClick={switchLanguaje}/>
        </div>
    )
}
